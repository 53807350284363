<template>
  <Modal
    v-model="visible"
    title="人员签到记录"
    width="1024"
    :styles="{top: '20px'}"
    :mask-closable="false"
    @on-cancel="returnPath">
    <div class="modal-body">
      <div class="signin-toolbar">
        <RadioGroup v-model="signinViewMode" type="button" class="pull-right">
          <Radio label="list">
            <Icon type="md-menu"></Icon>
          </Radio>
          <Radio label="calendar">
            <Icon type="md-calendar"></Icon>
          </Radio>
        </RadioGroup>
        <h3 class="pull-right">
          <Tag type="border" color="blue">签到{{totalSignedTimes}}次</Tag>
          <Tag type="border" color="blue">签到{{totalSignedDays}}天</Tag>
          <Tag type="border" color="blue">总工时：{{totalWorkingHours.toFixed(1)}} h</Tag>
        </h3>
        <div class="btn-group" role="group" aria-label="signin-toolbar-left">
          <Button type="default"
            icon="ios-arrow-back"
            @click="prevMonth">
          </Button>
          <DatePicker
            type="month"
            :value="curMonth"
            @on-change="handleChange"
            :placeholder="calendarYear+'年'+calendarMonth+'月'"
            style="width: 100px;margin:0 5px">
          </DatePicker>
          <Button type="default"
            icon="ios-arrow-forward"
            @click="nextMonth">
          </Button>
        </div>
      </div>
      <div v-show="signinViewMode === 'calendar'">
        <fullcalendar
          :events="events"
          :lang="locale"
          :viewDate="curMonth"
          @changeMonth="changeMonth"
          @dayClick="dayClick"
          @eventClick="eventClick">
        </fullcalendar>
      </div>
      <div v-show="signinViewMode === 'list'">
        <Table stripe :columns="columns" :data="dataViewPage" ref="table" @on-selection-change="selectRowChange"></Table>
        <div style="margin: 10px;overflow: hidden">
          <Button-group>
            <Button type="default" icon="md-refresh" @click="refreshList">刷新</Button>
            <Button type="primary" @click="exportData" :disabled="!rowSelected"><Icon type="ios-download-outline"></Icon> 导出原始数据</Button>
            <!-- <Button type="primary" @click="signinSummary" :disabled="!rowSelected"><Icon type="clock"></Icon> 考勤汇总</Button> -->
          </Button-group>
          <div style="float: right;">
            <Page :total="data.length" :page-size-opts="[10,20,50,100]" @on-change="changePage" @on-page-size-change="changePageSize" :current="pageCurrent" show-sizer></Page>
          </div>
        </div>
      </div>
      <div class="signin-dialog" :style="{height: dialogHeight()}" id="signinDetail">
        <div class="signin-dialog-header">
          <span class="signin-dialog-title">{{ dayDetailDate }}签到详情</span>
          <Button type="default" size="small" shape="circle" icon="close" class="pull-right" @click="closeDialog"></Button>
          <!--<hr>-->
        </div>
        <div class="signin-dialog-body">
          <table class="table" v-if="dayDetail.length>0" border="0" cellspacing="0" cellpadding="0">
            <thead>
              <tr>
                <th tooltip="签到时间">时间</th>
                <th tooltip="签到地点">地点</th>
                <th tooltip="进出门编号">门编号</th>
                <th tooltip="进出门方向">方向</th>
                <th tooltip="截图">截图</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="rec,index in dayDetail" :key="index">
                <td>{{getTime(rec.DateTime)}}</td>
                <td>{{rec.Location}}</td>
                <td>{{rec.Gate}}</td>
                <td>{{showDoorDirection(rec.Door)}}</td>
                <td v-if="rec.PhotoUrl&&rec.PhotoUrl!=='no'">
                  <Button type="default" size="small" @click="openGallery(rec.PhotoUrl)">查看</Button>
                </td>
                <td v-else>
                  未采集
                </td>
              </tr>
            </tbody>
          </table>
        <span v-else>没有签到记录哦:)~~</span>
        </div>
        <component ref="Lightbox" id="signinLightBox"
          :images="photoUrl"
          :is="showLightBox"
          :showCaption="showCaption"
          :showThumbs="showThumbs">
        </component>
      </div>
    </div>
    <div slot="footer">
      <Button class="pull-left" size="large" @click="returnPath">取消</Button>
      <Button type="primary" size="large" @click="refreshList">刷新</Button>
      <div style="clear: both;"></div>
    </div>
  </Modal>
</template>

<script>
import fullcalendar from '../../assets/libs/vue-fullcalendar.min'
import Lightbox from 'vue-image-lightbox'

require('vue-image-lightbox/dist/vue-image-lightbox.min.css')

export default {
  props: ['dataSelected', 'show'],
  components: {
    fullcalendar,
    Lightbox
  },
  data () {
    return {
      project: {},
      apiItem: {
        apiHost: '',
        apiService: 'ZztPrjBiz',
        apiAction: 'SigninData/',
        apiQuery: {
        }
      },
      apiData: {
      },
      loading: false,
      datatableData: [],
      curMonth: '',
      calendarYear: new Date().getFullYear(),
      calendarMonth: new Date().getMonth() + 1,
      calendarStartDate: '',
      calendarEndDate: '',
      projectId: this.$route.params.tid,
      userId: null,
      events: [],
      locale: 'zh',
      dayDetail: {},
      dayDetailDate: '',
      showModal: false,
      dialog: false,
      datepickerVal: '',
      signinViewMode: 'list',
      showLightBox: '',
      showThumbs: true,
      showCaption: true,
      photoUrl: [],
      rowSelected: false,
      rowSelectedList: [],
      pageCurrent: 1,
      pageSize: 10,
      columns: [
        { type: 'selection', width: 80, align: 'center' },
        {
          title: '日期',
          key: 'Day',
          // width: 120,
          ellipsis: true,
          sortable: true,
          render: (h, params) => {
            return h('span', {}, this.getDate(params.row.FirstTimeStr))
          }
        },
        { title: '当日首次',
          key: 'FirstTimeStr',
          // width: 150,
          sortable: true,
          render: (h, params) => {
            return h('span', {}, this.getTime(params.row.FirstTimeStr))
          }
        },
        { title: '当日末次',
          key: 'LastTimeStr',
          // width: 150,
          sortable: true,
          render: (h, params) => {
            return h('span', {}, this.getTime(params.row.LastTimeStr))
          }
        },
        {
          title: '操作',
          key: 'action',
          // fixed: 'right',
          // width: 180,
          render: (h, params) => {
            return h('Button-group', [
              h('Button', {
                props: {
                  type: 'primary',
                  ghost: true,
                  size: 'small'
                },
                style: {
                },
                on: {
                  click: () => {
                    // this.dayClick(params.row.Date, null)
                    this.dayClick(this.getDate(params.row.FirstTimeStr), null)
                  }
                }
              }, '详情')
            ])
          }
        }
      ],
      dataRaw: [],
      data: [],
      dataViewPage: [],
      visible: false,
      totalSignedDays: 0,
      totalSignedTimes: 0,
      totalWorkingHours: 0
    }
  },
  watch: {
    'show': function (val) {
      this.visible = val
      if (val) {
        if (this.dataSelected) {
          // this.calendarStartDate = getCurrentMonthFirst()
          // this.calendarEndDate = getCurrentMonthLast()
          this.initDataTable()
        }
      }
    },
    'dataSelected': function (val) {
      if (val) {
        this.userId = val.UserId
        this.initDataTable()
      }
    },
    'datatableData': function (val) {
      if (val) {
        this.events = []
        for (let i = 0; i < val.length; i++) {
          // let title = ''
          let event = {
            title: '签到' + val[i].SignedTimes + '次',
            // start: val[i].Date,
            start: this.getDate(val[i].FirstTimeStr),
            YOUR_DATA: {}
          }
          this.events.push(event)
          event = {
            title: val[i].WorkingHours.toFixed(1) + 'h',
            // start: val[i].Date,
            start: this.getDate(val[i].FirstTimeStr),
            YOUR_DATA: {}
          }
          this.events.push(event)
          // if (val[i].LastTimeStr.substr(10) === '2030-01-01') {
          //   title = '未知'
          // } else {
          //   title = val[i].LastTimeStr.substr(10)
          // }
          // event = {
          //   title: title,
          //   // start: val[i].Date,
          //   start: this.getDate(val[i].LastTimeStr),
          //   YOUR_DATA: {}
          // }
          // this.events.push(event)
          // if (val[i].FirstTimeStr.substr(10) === '2000-01-01') {
          //   title = '未知'
          // } else {
          //   title = val[i].FirstTimeStr.substr(10)
          // }
          // event = {
          //   title: title,
          //   // start: val[i].Date,
          //   start: this.getDate(val[i].FirstTimeStr),
          //   YOUR_DATA: {}
          // }
          // this.events.push(event)
        }
      }
    },
    'curMonth': function (val) {
      if (val) {
        console.log(val)
        // this.calendarStartDate = val + '-01'
        // let year = new Date(this.calendarStartDate).getFullYear()
        // let month = new Date(this.calendarStartDate).getMonth() + 1
        // this.calendarYear = year
        // this.calendarMonth = month
        // this.calendarEndDate = getLastDate(year, month)
        this.initDataTable()
      }
    },
    'data': function () {
      let vmInstance = this
      this.dataViewPage = this.data.filter(function (item, index) {
        return index < vmInstance.pageSize * vmInstance.pageCurrent && index >= vmInstance.pageSize * (vmInstance.pageCurrent - 1)
      })
    },
    'pageCurrent': function () {
      let vmInstance = this
      this.dataViewPage = this.data.filter(function (item, index) {
        return index < vmInstance.pageSize * vmInstance.pageCurrent && index >= vmInstance.pageSize * (vmInstance.pageCurrent - 1)
      })
    },
    'pageSize': function () {
      let vmInstance = this
      this.dataViewPage = this.data.filter(function (item, index) {
        return index < vmInstance.pageSize
      })
    }
  },
  mounted () {
    if (this.show) {
      this.visible = true
    }
    // $('#workerRecords').modal({show: true, backdrop: 'static', keyboard: false})
  },
  methods: {
    initDataTable () {
      if (this.visible && this.userId) {
        this.datatableData = []
        this.dayDetail = []
        this.$Api.Project.getProjectUserAttendance(this.$route.params.tid, this.calendarYear, this.calendarMonth, this.userId).then((respBody) => {
          console.log(respBody)
          this.getOSSData(respBody, (oss) => {
            this.datatableData = oss.SigninData
            this.dataRaw = oss.SigninData
            this.data = oss.SigninData
            this.totalSignedDays = 0
            this.totalSignedTimes = 0
            this.totalWorkingHours = 0
            this.totalSignedDays = oss.TotalSignedDays
            this.totalSignedTimes = oss.TotalSignedTimes
            this.totalWorkingHours = oss.TotalWorkingHours
            this.pageSize = 10
            this.pageCurrent = 1
            this.$nextTick(() => {
              window.scrollTo(0, 300)
            })
          })
        })
        .catch(err => {
          this.$Notice.error({
            title: '列表获取失败！',
            desc: err.message
          })
        })
      }
    },
    getOSSData (url, callback) {
      if (url) {
        this.$axios.get(url).then(
          response => {
            if (callback) {
              callback(response.data)
            } else {
              return response.data
            }
          }
        )
      }
    },
    selectRowChange (selection) {
      if (selection.length > 0) {
        this.rowSelected = true
        this.rowSelectedList = selection
      } else {
        this.rowSelected = false
        this.rowSelectedList = []
      }
    },
    changePage (page) {
      this.pageCurrent = page
    },
    changePageSize (pageSize) {
      this.pageSize = pageSize
    },
    exportData () {
      this.$refs.table.exportCsv({
        filename: 'zztdata.csv',
        columns: this.columns,
        data: this.rowSelectedList
      })
    },
    refreshList () {
      this.initDataTable()
    },
    returnPath () {
      this.datatableData = []
      this.dayDetail = []
      this.events = []
      this.userId = null
      this.dayDetailDate = ''
      this.dialog = false
      this.visible = false
      this.$emit('close')
      this.$Modal.remove()
    },
    getDate (time) {
      // time = time.replace('T', ' ')
      let date = new Date(time)
      return date.toLocaleDateString()
    },
    getTime (time) {
      // time = time.replace('T', ' ')
      let date = new Date(time)
      return date.toLocaleTimeString()
    },
    handleChange (date) {
      this.curMonth = date
      this.calendarYear = parseInt(date.split('-')[0])
      this.calendarMonth = parseInt(date.split('-')[1])
    },
    prevMonth () {
      // document.querySelector('.full-calendar-header .prev-month').click()
      this.calendarMonth -= 1
      if (this.calendarMonth === 0) {
        this.calendarMonth = 12
        this.calendarYear -= 1
      }
      this.curMonth = this.calendarYear + '-' + this.calendarMonth
    },
    nextMonth () {
      // document.querySelector('.full-calendar-header .next-month').click()
      this.calendarMonth += 1
      if (this.calendarMonth === 13) {
        this.calendarMonth = 1
        this.calendarYear += 1
      }
      this.curMonth = this.calendarYear + '-' + this.calendarMonth
    },
    changeMonth (start) {
      let curYear = parseInt(start.substr(0, 4))
      let curMonth = parseInt(start.substr(5, 2))
      if (start.substr(8) !== '01') {
        curMonth = curMonth * 1 + 1
        if (curMonth === 13) {
          curMonth = 1
          curYear += 1
        } else if (curMonth === 0) {
          curMonth = 12
          curYear -= 1
        }
      }
      this.calendarYear = curYear
      this.calendarMonth = curMonth
      // this.calendarStartDate = getFirstDate(curYear, curMonth)
      // this.calendarEndDate = getLastDate(curYear, curMonth)
      // this.initDataTable()
    },
    dayClick (day) {
      this.dialog = false
      this.dayDetail = []
      let date = this.$moment(day).format('YYYY-MM-DD')
      this.$Api.Project.getProjectUserAttendanceByDate(this.$route.params.tid, date, this.userId).then((respBody) => {
        this.dayDetail = respBody.Records
        this.showModal = true
        this.dayDetailDate = date
        this.$nextTick(() => {
          this.openDialog()
        })
      })
      .catch(err => {
        this.$Notice.error({
          title: '载入错误',
          desc: err.message
        })
      })
    },
    eventClick (event, jsEvent) {
      this.dayClick(event.start, jsEvent)
    },
    dialogHeight () {
      // let main = document.getElementsByClassName('layout-main')[0]
      if (!this.dialog) {
        // main.scrollTop = 0
        return '0px'
      } else {
        let dialogBox = document.getElementById('signinDetail')
        // main.scrollTop = dialogBox.offsetTop
        return dialogBox.scrollHeight + 'px'
      }
    },
    openDialog () {
      this.dialog = false
      this.$nextTick(() => {
        this.dialog = true
      })
    },
    closeDialog () {
      this.dialog = false
    },
    showDoorDirection (door) {
      if (door || door !== 0) {
        if (door % 2 === 1) {
          return '进'
        } else {
          return '出'
        }
      } else {
        return '未知'
      }
    },
    handleSigninPhoto () {
    },
    openGallery (url) {
      url = url.replace('-internal', '')
      this.showLightBox = 'Lightbox'
      this.photoUrl = [
        {
          thumb: url,
          src: url,
          caption: this.dayDetailDate
        }
      ]
      this.$nextTick(() => {
        this.$refs.Lightbox.showImage(0)
        let nodes = document.querySelectorAll('.vue-lb-container#signinLightBox')
        nodes.forEach((node) => {
          document.appendChild(node)
        })
      })
    }
  },
  beforeDestroy () {
    let nodes = document.querySelectorAll('.vue-lb-container#signinLightBox')
    nodes.forEach((node) => {
      node.remove()
    })
  }
}
Date.prototype.format = function (fmt) {
  var o = {
    'M+': this.getMonth() + 1,                 // 月份
    'd+': this.getDate(),                    // 日
    'h+': this.getHours(),                   // 小时
    'm+': this.getMinutes(),                 // 分
    's+': this.getSeconds(),                 // 秒
    'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
    'S': this.getMilliseconds()             // 毫秒
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
  }
  return fmt
}
// 获取当前月的第一天
// function getCurrentMonthFirst () {
//   var date = new Date()
//   return new Date(date.getFullYear(), date.getMonth(), 1).format('yyyy-MM-dd')
// }

// 获取当前月的最后一天
// function getCurrentMonthLast () {
//   var date = new Date(getCurrentMonthFirst())
//   var currentMonth = date.getMonth()
//   var nextMonth = currentMonth + 1
//   var nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1)
//   var oneDay = 1000 * 60 * 60 * 24
//   return new Date(nextMonthFirstDay - oneDay).format('yyyy-MM-dd')
// }

// function getFirstDate (year, month) {
//   return new Date(year, --month, 1).format('yyyy-MM-dd')
// }

// function getLastDate (year, month) {
//   var nextMonthFirstDay = new Date(year, month, 1)
//   var oneDay = 1000 * 60 * 60 * 24
//   return new Date(nextMonthFirstDay - oneDay).format('yyyy-MM-dd')
// }
</script>

<style>
.full-calendar-body .dates .dates-events .events-week .events-day {
  min-height: 80px !important;
}
.full-calendar-body .dates .week-row .day-cell {
  min-height: 80px !important;
}
.full-calendar-body .dates .dates-events .events-week .events-day .day-number {
  padding: 2px !important;
  font-size: 12px !important;
}
.full-calendar-body .dates .dates-events .events-week .events-day .event-box .event-item {
  font-size: 12px !important;
  padding: 0 0 0 4px !important;
  height: 14px !important;
  line-height: 14px !important;
}
.full-calendar-header {
  display: none !important;
}
.full-calendar-header .header-center .prev-month,
.full-calendar-header .header-center .title,
.full-calendar-header .header-center .next-month {
  font-size: 22px;
  padding: 10px;
}

.signin-toolbar {
  margin-bottom: 10px;
}

.signin-dialog {
  /*border-bottom: 1px solid #efefef;*/
  background: #f5f5f5;
  overflow: hidden;
  /*padding: 20px;*/
  transition: all 0.3s ease-in-out;
}
.signin-dialog-header {
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.signin-dialog-header .signin-dialog-title {
  background: #f5f5f5;
  padding-left: 20px;
  padding-right: 20px;
  white-space: nowrap;
  display: inline-block;
  /*z-index: 11;*/
  font-size: 16px;
  font-weight: bold;
  color: #777;
}
.signin-dialog-header hr {
  margin: 0;
  width: 100%;
  position: absolute;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #fff;
}
.signin-dialog-body {
  padding: 8px;
}
.signin-dialog table {
  width: 100%;
  column-span: 0;
  background: #fff;
  border: 1px solid #f2f2f2;
  /*margin: 8px;*/
}
.signin-dialog table th, .signin-dialog table td {
  padding: 8px;
  border-bottom: 1px solid #f5f5f5;
  border-left: 1px solid #f5f5f5;
  margin-left: -1px;
  margin-top: -1px;
  text-align: center;
}
.signin-dialog table th {
  background: #e3e3e3;
}
</style>
